import { createApp } from 'vue';
import '@/styles/global.scss';
import App from '@/apps/AppWebsite.vue';
import router from '@/routes/website'

import VueSplide from '@splidejs/vue-splide';
import GeneralGatewayHttps from '@/infra/gateway/generalGatewayHttps';
import InvestidorGatewayHttps from '@/infra/gateway/investidorGatewayHttps';
import DesenvolvedorGatewayHttps from './infra/gateway/desenvolvedorGatewayHttps';

import initSentry from '@/services/Sentry';

const generalGateway = new GeneralGatewayHttps();
const investidorGateway = new InvestidorGatewayHttps();
const desenvolvedorGateway = new DesenvolvedorGatewayHttps();

const app = createApp(App);

initSentry(app, router);

app.provide("generalGateway", generalGateway)
  .provide("investidorGateway", investidorGateway)
  .provide("desenvolvedorGateway", desenvolvedorGateway)
  .use(VueSplide)
  .use(router)
  .mount("#app");

